.border-radius-top-0 {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.border-radius-bottom-0 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.clickable-row {
    cursor: pointer;
    &:hover {
        td {
            background-color: $gray-lighter;
        }
    }
}

.list-group {
    .list-group-item {
        padding: 0;
        a {
            border-radius: 4px;
            color: $body-color;
            display: block;
            padding: 0.55rem 1.375rem;
            text-decoration: none;
            &:hover {
                background-color: $gray-lighter;
            }
        }
    }
}
