/* Tables */

.table {
    margin-bottom: 0;

    thead {
        th {
            border-top: 0;
            border-bottom-width: 1px;
            font-weight: 600;
            font-size: .875rem;

            i {
                margin-left: 0.325rem;
            }
        }
    }

    th,
    td {
        vertical-align: middle;
        line-height: 1;
        white-space: nowrap;
        padding: $table-cell-padding;
    }

    td {
        font-size: $default-font-size;

        img {
            width: 36px;
            height: 36px;
            border-radius: 100%;
        }

        .badge {
            margin-bottom: 0;
        }

        .btn-link {
            padding: 0 5px;
        }
    }

    tr {
        &:last-child {
            td {
                border-bottom: none;
            }
        }
    }

    &.table-borderless {
        border: none;

        tr,
        td,
        th {
            border: none;
        }
    }

    &.table-bordered {
        border-top: 1px solid $border-color;
    }

    &.select-table {
        tr {
            td {
                &:first-child {
                    padding-right: 10px;
                }
            }
        }

        th {
            color: $select-table-head;
            font-style: normal;
            font-weight: bold;
            font-size: 11px;
            line-height: 15px;
            text-transform: uppercase;
            padding-right: 5px;
            padding-left: 0;

            .form-check {
                margin-bottom: 0;
                width: 20px;
            }

            p {
                &.dark-text {
                    color: $table-color-dark;
                }
            }
        }

        td {
            font-family: Manrope;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: $select-table-cell-color;
            padding-top: 14px;
            padding-bottom: 14px;
            padding-left: 0;

            .form-check {
                width: 20px;
            }

            h6 {
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                line-height: 18px;
                color: $black;
                margin-bottom: 5px;
            }

            p {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                color: $text-muted;
                margin-bottom: 0;

                &.dark-text {
                    color: $table-color-dark;
                }

                &.fs-14 {
                    font-size: 14px;
                }
            }

            img {
                width: 44px;
                height: 44px;
                border-radius: 10px;
                margin-right: 15px;

                .rtl & {
                    margin-right: 0;
                    margin-left: 15px;
                }
            }

            .max-width-progress-wrap {
                width: 120px;
            }

            .form-check {
                margin-bottom: 0;
            }
        }
    }

    &.table-sm {
        td, th {
            font-size: 0.812rem;
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }

    &.table-hover {
        > tbody > tr:hover > * {
            background: $table-hover-bg;
        }
    }

    &.table-striped {
        > tbody > tr:nth-of-type(#{$table-striped-order}) > * {
            background: $table-striped-bg;
        }
    }

}

.table > :not(:last-child) > :last-child > *, .jsgrid .jsgrid-table > :not(:last-child) > :last-child > * {
    border-bottom-color: $border-color;
}

.table > :not(caption) > * > *, .jsgrid .jsgrid-table > :not(caption) > * > * {
    box-shadow: none;
}
