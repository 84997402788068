.btn:active {
    &:not(.btn-link) {
        color: #fff !important;
    }
}

.table {
    tr {
        td {
            vertical-align: middle;
        }
        &.text-white {
            td {
                color: #fff;
            }
        }
        &.text-danger {
            td {
                color: #dc3545;
            }
        }
    }
}

.list-group {
    .list-group-item {
        &.active {
            background-color: $primary;
            border-color: $primary;
            a {
                color: #fff;
                &:hover {
                    background-color: $primary;
                }
            }
        }
    }
}

.search-wrapper {
    position: relative;
    .results {
        background-color: #fff;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        max-height: 200px;
        overflow: auto;

        table {
            tr {
                cursor: pointer;
            }
        }
    }
}

.overlay {
    background: rgba(255,255,255,.7);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    > div {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 50%;
        margin-top: -1rem !important;
    }
}

.select2-container {
    width: 100% !important;

    .select2-selection--single {
        font-size: .875rem;
        height: 2.75rem;
        border-color: #dee2e6;

        .select2-selection__rendered {
            line-height: 2.75rem;
            padding-right: 1.375rem;
            padding-left: 1.375rem;
        }

        .select2-selection__clear, .select2-selection__arrow {
            height: 2.75rem;
        }
    }
}

.mb-2rem {
    margin-bottom: 2rem !important;
}
