//Icons
@import "simple-line-icons";
//@import "puse-icons-feather";
@import '~jquery-ui/themes/base/theme.css';
@import '~jquery-ui/themes/base/datepicker.css';

//Theme
@import "common/light/common";
@import "variables";
@import "mixins/menu";
@import "horizontal-wrappers";
@import "horizontal-menu";
@import "select2/dist/css/select2.min.css";
@import "custom";
@import "helpers";
