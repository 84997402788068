.page-body-wrapper {
  padding-left: 0;
  padding-right: 0;
  &.full-page-wrapper {
    .content-wrapper {
      width: 100%;
      height: 100vh;
      max-width: none;
    }
  }
}

.horizontal-menu {
  &.fixed-on-scroll {
    + .page-body-wrapper {
      padding-top: 2.75rem;
    }
  }
}

.main-panel {
  background: $content-bg;
  width: 100%;
  @include media-breakpoint-down(md) {
    padding-top: 60px;
  }
}

.content-wrapper {
  padding: 2rem 0;
  @include make-container();
  // @include make-container-max-widths();
  min-height: calc(100vh - #{$horizontal-navbar-height} - #{$footer-height});
  @include flex-grow(1);
}
